import React, { useState, useEffect, useRef, useMemo, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { searchProperties, getPropertydeatils, getPropertyImages, savePageInfo } from '../services/apiService';
import { useDispatch } from 'react-redux';
import { setPropertyAddress, setPropertyId, setPropertyDetails, setPropertyImages, clearSavedImages, clearPropertyDetails } from '../../slices/propertySlice';
import debounce from 'lodash/debounce';
import ChatLoader from '../services/chat-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';

import { clearPreferenceQuestionList, clearCmaList, clearSuggestedOffer, clearMarket, clearManor } from '../../slices/preferenceSlice';
import { clearMarketQuestionList } from '../../slices/marketSlice';
import { clearManorQuestionList } from '../../slices/manorSlice';
import { clearMatchQuestionList } from '../../slices/matchSlice';
import { LoaderContext } from '../services/LoaderContext';
import { scrollToBottom } from '../utils/Common';


const pageName = "property_home";

const HomeContent = () => {
    const bottomRef = useRef(null);
    const navigate = useNavigate();
    const propertyAddressInput = useRef('');
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const hasFetched = useRef(false);
    const videoRef = useRef(null);
    const [isOpen, openDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchId, setSearchId] = useState(sessionStorage.getItem('propertyId'));
    const [loading, setLoading] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [addressSelected, setAddressSelected] = useState(false); // New state to track if an address is selected
    const { showLoader, hideLoader } = useContext(LoaderContext);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                openDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const savePageSession = useCallback(async () => {
        await savePageInfo(pageName);
    }, []);

    useEffect(() => {
        if (!hasFetched.current) {
            savePageSession()
            hasFetched.current = true;
        }
        //for autoplay CMA video
        if (videoRef.current) {
            videoRef.current.play().catch((error) => {});
        }
        scrollToBottom(bottomRef);
    }, [hasFetched, savePageSession]);

    // Debounce the API call
    const debouncedSearch = useMemo(() => {
        return debounce(async (term) => {
            if (term.length === 0) {
                setFilteredOptions([]);
                openDropdown(false);
                setLoading(false);
                return;
            }

            try {
                const response = await searchProperties({ "user_str": term });
                if (response.code === 200) {
                    setFilteredOptions(response.response);
                    openDropdown(true);
                } else {
                    setFilteredOptions([]);
                }
            } catch (error) {
                setFilteredOptions([]);
                toast.error("Error fetching search results");
            }
            setLoading(false);
        }, 500);
    }, [setFilteredOptions, openDropdown, setLoading]);

    useEffect(() => {
        if (searchTerm.trim() !== '' && !addressSelected) {
            setLoading(true);
            openDropdown(true); // Open the dropdown while loading
            debouncedSearch(searchTerm);
        } else {
            setFilteredOptions([]);
            openDropdown(false);
            setLoading(false);
        }

        return () => {
            debouncedSearch.cancel(); // Cancel any pending debounced calls on unmount or dependency change
        };
    }, [searchTerm, debouncedSearch, addressSelected]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setLoading(true);  // Set loading to true when the input changes
        setAddressSelected(false); // Reset the address selected state
        openDropdown(true);
        setNextPage(false);
    };

    const handleOptionClick = (option) => {
        setSearchTerm(option.UnparsedAddress);
        setSearchId(option.ListingId);
        openDropdown(false);
        setNextPage(true);
        dispatch(setPropertyAddress(option.UnparsedAddress));
        dispatch(setPropertyId(option.ListingId));
        setAddressSelected(true); // Set address selected state to true
        // getPropertyDetails(option.ListingId);
    };

    const getPropertyDetails = async (propertyId) => {
        dispatch(clearPropertyDetails());
        try {
            showLoader();
            const listing = await getPropertydeatils(propertyId);
            if (listing.code === 200) {
                const prList = listing['response'][propertyId];
                dispatch(setPropertyDetails({
                    propertyBeds: prList.BedroomsTotal,
                    propertyBaths: prList.BathroomsFull,
                    propertySqft: prList.LivingArea,
                    propertyListPrice: prList.ListPrice,
                    propertyUnparsedAddress: prList.UnparsedAddress
                }));

                // Redux store info cleared
                dispatch(clearCmaList());
                dispatch(clearSuggestedOffer());
                dispatch(clearManor());
                dispatch(clearMarket());
                dispatch(clearPreferenceQuestionList());
                dispatch(clearMatchQuestionList());
                dispatch(clearManorQuestionList());
                dispatch(clearMarketQuestionList());

                navigate(`/${propertyId}`);
            }
            hideLoader();
        }
        catch (error) {
            hideLoader();
            toast.warning("Failed to fetch data.");
        }
    };

    const submitAddress = () => {
        if (!propertyAddressInput.current.value || propertyAddressInput.current.value.trim() === "") {
            propertyAddressInput.current.focus();
            toast.warning("Please enter a valid address.");
        }
        else {
            getPropertyDetails(searchId)
            dispatch(clearSavedImages());
            getImages(searchId);
        }
    };

    const getImages = async (propertyId) => {
        try {
            const response = await getPropertyImages({ "mls_id": propertyId });
            dispatch(clearSavedImages());
            if (response.code === 200) {
                if (response?.response?.images?.length > 0) {
                    dispatch(setPropertyImages(response.response.images[0]));
                }
            }
        } catch (error) {
            toast.warning("Failed to fetch data.");
        }
    };

    return (
        <>
            <div className="page-content text-center mb-5 intro-header heading-animation" ref={bottomRef}>
                <h2 className="text-center heading-color">
                    <b> Welcome to the future of home-buying, where you are always ahead! </b>
                </h2>
            </div>
            <div className="d-flex home-container light-bg px-4">
                <div className="intro-vid w-50">
                    <div className="video-container p-3 cs-card green-border">
                        <div className="message-holder mira-chat ml-85 mt-2">
                            <div className="message-bubble">
                                <div className="label">
                                    Agent Mira
                                </div>
                                <div className="message-text">
                                    Where's the House?
                                </div>
                            </div>
                            <div className="address-input mt-3 w-100">
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                    autoFocus
                                    ref={propertyAddressInput}
                                    className="font-size-lg"
                                    placeholder='What’s the address of the house you want to make an offer on?'
                                />
                                {nextPage === false && (
                                    <div ref={dropdownRef}>
                                        <ul className={`dropdown-search-list ${isOpen ? 'open' : ''}`}>
                                            {loading ? (
                                                <li className="dropdown-search-option">
                                                    <ChatLoader />
                                                </li>
                                            ) : (
                                                filteredOptions.length > 0 ? (
                                                    filteredOptions.map((result, index) => (
                                                        <li key={index} onClick={() => handleOptionClick(result)} className="dropdown-search-option">
                                                            {result.UnparsedAddress}
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li className="dropdown-search-option">No address found</li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}
                                <div className={`submit-button next arrow ${nextPage ? '' : 'chat_disable'}`} onClick={submitAddress}>
                                    <FontAwesomeIcon icon={faCircleChevronRight} className='font-size-mid color-blue' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cs-card search-box-highlight p-3">
                        <div className="d-flex flex-column">
                            <div className="heading3 heading-color">Offer Savant</div>
                            <div className="normal-text font-size-lgi line-height me-2">
                                Take fifteen minutes to craft a winning offer, without over-paying. I'm here to guide you every step of the way, with my market smarts matched to your unique needs.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-savant address-field-custom-width w-50">
                    <div className="p-2">
                        <div className="agent-mira-position p-2">
                            <div>
                                <div className="normal-text font-size-lgi line-height">
                                Buying a home is one of life’s biggest investments. Let Agent Mira make it seamless, stress-free, and full of possibilities.
                                </div>
                            </div>
                        </div>
                        <div className="intro-video">
                            <video muted ref={videoRef} controls>
                            <source src={`${process.env.PUBLIC_URL}/video/intro.mp4`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeContent;
