import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { loginData } from '../../slices/loginSlice';
import { useSelector } from 'react-redux';

const AuthGuard = ({ children }) => {
  const { token } = useAuth();
  const location = useLocation();
  const userDetails = useSelector(loginData);

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (userDetails?.full_access === 0 && (location.pathname.includes("/offer") || location.pathname.includes("/match"))) {
    // Redirect if the user does not meet the condition for accessing the "/offer" or "/match" route
    return <Navigate to="/update-access" />;
  }

  if (userDetails?.full_access === 1 && location.pathname.includes("/update-access")) {
    return <Navigate to="/" />;
  }

  if (userDetails?.isAdmin === 0 && location.pathname.includes("/user-management")) {
    // Redirect if the user does not meet the condition for accessing the "/offer" route
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthGuard;
