import React, { useEffect, useState, useContext, useRef } from 'react';
import PreferenceHomeContent from './content';
import Header from '../header/header';

import { useParams } from 'react-router-dom';
import { setPage } from '../../slices/userJourney';
import { useSelector, useDispatch } from 'react-redux';
import { getQuestions, getMarkedList, getAllUserHistory } from '../services/apiService';
import { addPreferenceQuestion, preferenceData } from '../../slices/preferenceSlice';
import { validatePropertyId, DataBinder } from '../utils/Common';
import { LoaderContext } from '../services/LoaderContext';

const Preferences = () => {
    const { propertyid } = useParams();
    const dispatch = useDispatch();
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const preQuestionList = useSelector(preferenceData);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [propertyPreferenceQuestions, setPropertyPreferenceQuestions] = useState(preQuestionList);
    const hasFetchedData = useRef(false);

    // validate the propertyid so that we can route the user details and if not, return error
    useEffect(() => {
        if (propertyid !== "" && validatePropertyId(propertyid)) {
            dispatch(setPage(`/${propertyid}/preferences`))
        }
        else {
            dispatch(setPage('/'))
        }
    }, [propertyid, dispatch])


    useEffect(() => {
        const processPreferenceMarkedData = async () => {
            showLoader();
            const userPreferenceHistory = await getAllUserHistory(propertyId, 'user_preferences');
            const markedResponse = await getMarkedList('user_preferences', propertyId);
            const questionResponse = await getQuestions(propertyId, 'user_preferences');
            let finalQuestionList = [...DataBinder(userPreferenceHistory.response, markedResponse.response.user_preferences)]

            const questionExists = finalQuestionList.some(question => question.question_id === questionResponse.response.question_id);
            if (!questionExists) {
                finalQuestionList.push(questionResponse.response);
            }
            setPropertyPreferenceQuestions(finalQuestionList);
            hideLoader();
        }

        if (!hasFetchedData.current && propertyPreferenceQuestions.length === 0) {
            processPreferenceMarkedData();
            hasFetchedData.current = true; // Ensure API call is only made once
        }
        
    }, [propertyId, showLoader, hideLoader, propertyPreferenceQuestions.length]);

    useEffect(() => {
        if (propertyPreferenceQuestions?.length > 0) {
            dispatch(addPreferenceQuestion(propertyPreferenceQuestions));
        }
    }, [propertyPreferenceQuestions, dispatch]);



    return (
        <>
            <div className="d-flex page border-on flex-column">
                <Header />
                
                <div className="page-content">
                    <PreferenceHomeContent propertyPreferenceQuestions={propertyPreferenceQuestions} setPropertyPreferenceQuestions={setPropertyPreferenceQuestions} />
                </div>
            </div>
        </>
    )
}

export default Preferences