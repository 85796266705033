import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    propertyAddress: null,
    propertyId: null,
    propertyDetails: {
        propertyBeds: null,
        propertyBaths: null,
        propertySqft: null,
        propertyListPrice: null,
        propertyUnparsedAddress: null
    },
    propertyImages: null,
    showOffer: false,
}

export const propertySlice = createSlice({
    name: 'property',
    initialState: initialState,
    reducers: {
        setPropertyAddress: (state, action) => {
            state.propertyAddress = action.payload
        },
        setPropertyId: (state, action) => {
            state.propertyId = action.payload
        },
        setPropertyDetails: (state, action) => {
            state.propertyDetails =  action.payload
        },
        setPropertyImages: (state, action) => {
            state.propertyImages = action.payload
        },
        clearSavedImages: (state) => {
            state.propertyImages = []
        },
        setOfferButton: (state) => {
            state.showOffer = true
        },
        clearPropertyDetails: (state) => {
            state.propertyDetails = {
                propertyBeds: null,
                propertyBaths: null,
                propertySqft: null,
                propertyListPrice: null,
                propertyUnparsedAddress: null
            };
        },
    }
})

export const {
    setPropertyAddress, setPropertyId, setPropertyDetails, setPropertyImages, clearSavedImages, setOfferButton, clearPropertyDetails
} = propertySlice.actions;

export const propertyData = (state) => state.propertySlice;
export const getImagesList = (state) => state.propertySlice.propertyImages;

export default propertySlice.reducer;
