import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    questions: []
};

const matchSlice = createSlice({
    name: 'match',
    initialState,
    reducers: {
        addQuestion: (state, action) => {
            state.questions = action.payload
        },
        clearMatchQuestionList: (state) => {
            state.questions = [];
        }
    },
});

export const { addQuestion, clearMatchQuestionList } = matchSlice.actions;
export const matchData = (state) => state.matchSlice.questions;

export default matchSlice.reducer;
