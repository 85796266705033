import React from "react";
import { useNavigate } from "react-router-dom";

const NoComparable = ({ isShow }) => {
    
    const navigate = useNavigate();

    return (
       <>
            <div className="message-holder w-80 ml-98 mb-5">
                <div className="cs-card h-100 position-relative mt-4 p-5 text-center">
                <div className="card-header">
                    <h4>
                        Unique Home Alert!
                    </h4>
                </div>
                    <div className="card-body">
                        <h5>
                            This home is one of a kind! We’ll do a special analysis just for this property. One of our agents will reach out to help you out soon.
                        </h5>

                        {isShow && <>
                            <div className="d-flex js-center">
                                <div className="button-primary w-20 mt-3 text-center" onClick={() => navigate('/')}>
                                    Home
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoComparable