import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const ChatLoader = () => {

    return (
       <>
            <div className="chat-loader">
                <FontAwesomeIcon icon={faCircle} className="dot" />
                <FontAwesomeIcon icon={faCircle} className="dot" />
                <FontAwesomeIcon icon={faCircle} className="dot" />
            </div>
        </>
    )
}

export default ChatLoader