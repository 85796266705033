import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    login_token: null,
    logged_in: 0,
    is_recurring: null,
    nick_name: null,
    isSaved: false,
    firstName: null,
    lastName: null,
    isAdmin: null,
    full_access: null,
    lite_access: null
}

export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            state.login_token = action.payload.token
            state.is_recurring = action.payload.is_recurring
            state.nick_name = action.payload.nick_name
            state.firstName = action.payload.firstName
            state.lastName = action.payload.lastName
            state.isAdmin = action.payload.is_admin
            state.isSaved = action.payload.hasOwnProperty('isSaved') ? action.payload.isSaved : false;
            state.full_access = action.payload.full_access;
            state.lite_access = action.payload.lite_access;
            state.logged_in = 1
        },
        logout: (state) => {
            state.login_token = null
            state.is_recurring = null
            state.nick_name = null
            state.logged_in = 0
            state.isSaved = false
            state.firstName = null
            state.lastName = null
            state.isAdmin = null
            state.full_access = null
            state.lite_access = null
        }
    }
})

export const { login, logout } = loginSlice.actions;

export const loginData = (state) => state.loginSlice;
export const getToken = (state) => state.loginSlice.login_token;

export default loginSlice.reducer;
