import React, { useState, useEffect } from "react";
import ReactECharts from 'echarts-for-react';
import ChatLoader from "../../services/chat-loader";

const FirstChart = ({ chartList }) => {
    const [option, setChartOptions] = useState({});
    const [question, setChartData] = useState({});
    const [showCharts, setShowCharts] = useState(false);
    
    useEffect(() => {
        const setupChart = (chartsValue) => {
            setChartData(chartsValue);
        
            setTimeout(() => {
                if (Object.keys(chartsValue).length > 0) {
                    let xdata = [];
                    let data_bar = [];
                    let data_line = [];
        
                    chartsValue['chart1']['_roworder'].forEach(que => {
                        xdata.push(chartsValue['chart1']['_rowoptions'][que]);
                        if (chartsValue['chart1']['data_bar'][que] !== undefined && chartsValue['chart1']['data_bar'][que] !== null) {
                            data_bar.push(chartsValue['chart1']['data_bar'][que]);
                        }
                        if (chartsValue['chart1']['data_line'][que] !== undefined && chartsValue['chart1']['data_line'][que] !== null) {
                            data_line.push(chartsValue['chart1']['data_line'][que]);
                        }
                    });
        
                    const option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'none', // Ensures the tooltip aligns to the exact point on both axis
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            },
                            formatter: function (params) {
                                let tooltipText = `${params[0].axisValueLabel}<br/>`;
                                params.forEach(param => {
                                    tooltipText += `${param.marker} ${param.seriesName}: ${param.value === 0 ? '0' : param.value}<br/>`;
                                });
                                return tooltipText;
                            }
                        },
                        legend: {
                            data: ['Listings Change %', 'Listings'],
                            left: 'left',
                            top: 'top',
                            show: true,
                            textStyle: {
                                fontSize: '15',
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: xdata,
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: '{value}'
                            },
                            min: function(value) {
                                return Math.floor(value.min * 1.1); // Adjust to show negative values correctly
                            }
                        },
                        series: [
                            {
                                name: 'Listings Change %',
                                type: 'bar',
                                data: data_bar,
                                z: 10 // Higher z index to avoid overlap issues with the lines
                            },
                            {
                                name: 'Listings',
                                type: 'line',
                                data: data_line,
                                showSymbol: true,
                                showAllSymbol: true,
                                connectNulls: true,
                                z: 5 // Ensure lines are rendered over the bars but below tooltips
                            }
                        ]
                    };
        
                    setChartOptions(option);
                    setShowCharts(true);
                }
            }, 300);
        };
        

        if(chartList && Object.keys(chartList).length > 0){
            setupChart(chartList);
        }
        
    }, [chartList]);


   
    

    return (
        <>
            {showCharts ? 
                <>
                    <ReactECharts option={option} />

                    {question && question?.chart1?.insights?.length > 0 && 
                        <ul className="insights-font-size">
                            {question?.chart1?.insights.map((data, index) => (
                                <li key={index}>{data}</li>
                            ))}
                        </ul>
                    }
                </>
            : 
            <ChatLoader />}
        </>
    );
};

export default FirstChart;
