import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page:null,
}

export const userJourneySlice = createSlice({
    name: 'userJourney',
    initialState: initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload
        }
    }
})

export const {
    setPage
} = userJourneySlice.actions;

export const userJourneyData = (state) => state.userJourneySlice;

export default userJourneySlice.reducer;
