import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { generateOfferReport, generateCmaReport, getStatusOfOfferDownload, downloadOfferReport, sendOfferEmail  } from '../services/apiService';
import { getCma } from '../../slices/preferenceSlice';
import { rangeInfo  } from '../../slices/marketSlice';
import { toast } from 'react-toastify';
import homeChatGif from '../../assets/gifs/home_chat.gif';
import Modal from '../utils/Modal';
import { LoaderContext } from '../services/LoaderContext';


const cs_width = "35%";

const DownloadPdfFile = ({pageName, onData}) => {

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadMessage, setDownloadMessage] = useState("CMA report download is in progress, please wait.");
    const propertyDetails = useSelector((state) => state.propertySlice.propertyDetails);
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const listCma = useSelector(getCma);
    const rangeList = useSelector(rangeInfo);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [showModal, setShowModal] = useState(false);
    const [showEmailSuccessModal, setEmailSuccessModal] = useState(false);
    const [emailInput, setEmailId] = useState('');
    
    const downloadCmaPdf = async () => {
        setShowDownloadModal(true);
        const apiResponse = await generateCmaReport(propertyId, { "city": rangeList.city });
        if (apiResponse.code === 200) {
            // toast.info("CMA report download is in progress, please wait.");
            callProcessData(apiResponse.response);
        } 
        else {
            setShowDownloadModal(false);
        }
    }

    const downloadOfferPdf = async () => {
        setDownloadMessage("Offer download is in progress, please wait.");
        setShowDownloadModal(true);
        const apiResponse = await generateOfferReport(propertyId, { "city": rangeList.city });
        if (apiResponse?.code === 200) {
            // toast.info("Offer download is in progress, please wait.");
            callProcessData(apiResponse.response);
        } 
        else {
            setShowDownloadModal(false);
        }
    }
    
    const callProcessData = async (processList) => {
        const pdfResponse = await getStatusOfOfferDownload(propertyId,processList.process_id);
        if (pdfResponse?.response?.status === "in_progress") {
            setDownloadMessage("Preparing offer please wait.");
            setTimeout(() => {
                callProcessData(processList);
            }, 10000);
        } 
        else if(pdfResponse?.response?.status === "completed"){
            downloadBlobResponse(pdfResponse.response);
            setDownloadMessage("File prepared getting downloaded, please wait.");
        }
        else  {
            setDownloadMessage("An error occurred while downloading the file., please try again later.");
            setTimeout(() => {
                setShowDownloadModal(false);
            }, 10000);
        }
    };
    
    const downloadBlobResponse = async (processList) => { 
        try {
            const pdfResponse = await downloadOfferReport(propertyId,processList.process_id);
            if (pdfResponse) { // Check if the response is defined
                const blob = pdfResponse.data; // Get the blob data from the response directly
                if( blob.size > 0){
                    const downloadUrl = URL.createObjectURL(blob); // Create a URL for the blob
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    const propertyAddress = propertyDetails?.propertyUnparsedAddress.replace(/[\s,]+/g, '_');
                    const currentDateTime = new Date().toISOString().replace(/:/g, '-');
                    const fileName = `${propertyAddress}_${currentDateTime}.pdf`;

                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(downloadUrl);
                    if(pageName === 'offer'){
                        onData(true);
                    }
                }
                setShowDownloadModal(false);
            } 
            else {
                setShowDownloadModal(false);
                toast.error("Failed to download the file.");
            }
        } 
        catch (error) {
            setShowDownloadModal(false);
            toast.error("An error occurred while downloading the file.");
        }
    };


    // ==========================Email CMA Pdf==========================================
    const handleInputChange = (event) => {
        event.preventDefault();
        setEmailId(event.target.value);
    };

    const validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const sendEmail = async () => {
        if (validateEmail(emailInput)) {
            showLoader();
            try {
                const payload = { "email": emailInput, "mls_id": propertyId, "city": rangeList?.city, "category": "cma" }
                const response = await sendOfferEmail(payload);
                if (response?.code === 200) {
                    checkModal();
                    checkEmailModal();
                    hideLoader();
                }
                else {
                    hideLoader();
                }
            }
            catch (error) {
                hideLoader();
            }
        }
        else {
            toast.warning("Please enter a valid email address.");
        }
    };

    const checkModal = () => {
        setEmailId('');
        setShowModal((prevState) => !prevState);
    };
    
    const checkEmailModal = () => {
        setEmailSuccessModal((prevState) => !prevState);
    };

    return (
        <>  
            {pageName === 'match' && 
                <>
                    <div className="tab-row mt-3">
                        Here’s your Smart CMA Report!
                        <p className={`btn-download ${!listCma || showDownloadModal ? 'disable' : ''}`} onClick={() => downloadCmaPdf()}>
                            <span className="avocado-avenue">Download now </span>
                        </p>
                    </div>
                    <div className="tab-row mt-3">
                        <p className={`btn-download ${!listCma || showDownloadModal ? 'disable' : ''}`} onClick={checkModal}>
                            <span className="avocado-avenue">Click here to email CMA report! </span>
                        </p>
                    </div>
                </>
            }

            {pageName === 'offer' && 
                <div className={`button-secondary mw-10em bg-trns ${!listCma || showDownloadModal ? 'disable' : ''}`} onClick={() => downloadOfferPdf()}>
                    Download
                </div>
            }

            {showDownloadModal &&
                <div className="message-holder w-40 mb-5 card-sticky message-download">
                    <div className="cs-card h-100 position-relative p-3 text-center">
                        <div className="card-body">
                            <h5 className="mt-3 loader-text">
                                {downloadMessage}
                            </h5>
                            <div className="text-center">
                                <img src={homeChatGif} width="80" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showEmailSuccessModal ?
                <div className="offer_done_popup">
                    <div className="popup_container flex-column justify-content-center align-center">
                        <img alt="" src={`${process.env.PUBLIC_URL}/images/market/group-1171275857.svg`} style={{ "width": "50%" }} />
                        <div className="heres-your-offer">
                            Great job!
                        </div>
                        <p className="mt-2 mb-3">
                            We have received your request and are on it.
                            You should receive the Offer details in your inbox in a few minutes.
                        </p>
                        <div className="d-flex w-100 justify-content-evenly">
                            <button className="button-primary mw-10em w-min" onClick={() => checkEmailModal() }>
                                <div className="ssx_get-started text-center">Back</div>
                            </button>
                        </div>
                    </div>
                </div>
            : <></>}


            <Modal show={showModal} onClose={() => setShowModal(false)} cswidth={cs_width}>
                <div className="mb-2">
                    <label for="userEmail" className="form-label">To get a copy of CMA report.</label>
                    <input type="email" onChange={handleInputChange} value={emailInput} className="form-control" id="userEmail" placeholder="Enter your email.." />
                    <div className="d-flex js-center">
                        <div className="button-primary mw-10em mt-5" onClick={sendEmail}>
                            Submit
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DownloadPdfFile
