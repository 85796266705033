import React, { useState, useEffect, useContext } from "react";
import '../../../assets/market/chart.css';
import Modal from '../../utils/Modal';
import ChatLoader from "../../services/chat-loader";
import FirstChart from "../charts/chart-one";
import SecondChart from "../charts/chart-two";
import ThirdChart from "../charts/chart-three";
import FourthChart from "../charts/chart-four";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp , faChevronDown  } from '@fortawesome/free-solid-svg-icons';
import { propertyMarketCharts } from '../../services/apiService';
import { LoaderContext } from '../../services/LoaderContext';

const cs_width = "80%";

const MarketCharts = (chartsList) => {
    const { city, group, county, ...riskLevels } = chartsList.chartsList;
    const [showModal, setShowModal] = useState(false);
    const [riskKey, checkRiskKeys] = useState(false);
    const [cityCounty, setClickdata] = useState({});
    const [chartObject, setChartObject] = useState({});
    const { showLoader, hideLoader } = useContext(LoaderContext);


    const chartModalPoup = (data, index) => {
        if(data !== ''){
            showLoader();
            let collectPayload = {}
            if(index === 0){
                collectPayload = {"county": data};
            }
            if(index === 1){
                collectPayload = {"city": data};
            }
            if(index === 2){
                collectPayload = {"group": data};
            }
            setClickdata(collectPayload);
            getChartsListing(collectPayload)
        }
    };

    const [expanded, setExpanded] = useState([false,false,true]);
      
    const handleChartClick = (index) => {
        setExpanded((prev) => {
        const newExpanded = [...prev];
        newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    useEffect(() => {
        if (Object.keys(riskLevels).length > 0) {
            checkRiskKeys(true);
        } 
    }, [riskLevels]);

    const getChartsListing = async (payload) => {
        try {
            const chartResponse = await propertyMarketCharts(payload);
            if (chartResponse.code === 200) {
                setChartObject(chartResponse.response);
                setShowModal(!showModal);
                hideLoader();
            }
        } catch (error) {}
    };    


    return (
        <>
            <div className="chart_component-372">
                {!city &&
                    <ChatLoader />
                }

                {(chartsList && riskKey) &&
                    <>
                        <div className="chart_group-div" id="chartAccordion">
                            {Object.keys(riskLevels).map((key, index) => (
                                <>
                                    {index <=2 &&
                                        <div className="chart_line-parent" key={key}>
                                            
                                            <div className="chart_frame-parent9">
                                                <img className="chart_frame-child1" onClick={() => handleChartClick(index)} loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/level_1.svg`} />
                                                <div className="chart_frame-parent10">
                                                    <div key={key}>
                                                        <h2 id={`heading${index}`}>
                                                            <div className={`chart_frame-parent11 ${!expanded[index] ? 'collapsed' : ''}`} onClick={() => handleChartClick(index)} aria-expanded={expanded[index]}>

                                                                {index === 0 ? (
                                                                    <>
                                                                        <b className="chart_the-city">The county</b>
                                                                    </>
                                                                ) : index === 1 ? (
                                                                    <>
                                                                        <b className="chart_the-city">The city</b>
                                                                    </>
                                                                ) : index === 2 ? (
                                                                    <>
                                                                        <b className="chart_the-city">The home category</b>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    </>
                                                                )}

                                                                
                                                                <div>
                                                                    {expanded[index] === true &&
                                                                        <FontAwesomeIcon icon={faChevronUp} className="font-size-mid me-3" />
                                                                    }
                                                                    {(expanded[index] === false || !expanded[index]) &&
                                                                        <FontAwesomeIcon icon={faChevronDown} className="font-size-mid me-3" />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </h2>
                                                        <div  id={`collapse${index}`} className={`accordion-collapse collapse ${expanded[index]? 'show' : ''}`} aria-labelledby={`heading${index}`} data-bs-parent="#chartAccordion">
                                                            {(index === 1 || index === 0) ? <div className="chart_line-div"></div> :<></>}
                                                            <div className="chart_frame-wrapper6">
                                                                <div className="chart_frame-parent12">
                                                                    <div className="chart_whats-the-current-level-of-co-parent">
                                                                        <div className="chart_whats-the-current">

                                                                            {index === 0 ? (
                                                                                <>
                                                                                    {riskLevels?.show?.county}
                                                                                </>
                                                                            ) : index === 1 ? (
                                                                                <>
                                                                                    {riskLevels?.show?.city}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <u className="chart_show-details" onClick={() => chartModalPoup(key, index)}>Show me why</u>
                                                                    </div>
                                                                    <div className="chart_frame-parent13">
                                                                        <div className="chart_level-visualization-parent">
                                                                            <div className="chart_level-visualization">
                                                                                <div className="chart_level-elements">
                                                                                    {Object.keys(riskLevels[key]).map((riskLevel, index2) => (
                                                                                        <>
                                                                                            {index2 === 0 ? <>
                                                                                                <div className="chart_level-elements-inner" key={riskLevel}>
                                                                                                    <div className="chart_frame-parent14">
                                                                                                        {riskLevels[key][riskLevel] === 1 ?
                                                                                                            <>
                                                                                                                <img className="chart_frame-child2" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_5.svg`} />
                                                                                                                <div className="chart_ellipse-parent1">
                                                                                                                    <div className="chart_frame-child8"></div>
                                                                                                                    <div className="chart_div2">1</div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <img className="chart_frame-child2" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_1.svg`} />
                                                                                                                <div className="chart_ellipse-parent">
                                                                                                                    <div className="chart_ellipse-div"></div>
                                                                                                                    <div className="chart_div">1</div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </> : <></>}
                                                                                            {index2 === 1 ? <>
                                                                                                <div className="chart_level-items">
                                                                                                    <div className="chart_frame-parent15">
                                                                                                        {riskLevels[key][riskLevel] === 1 ?
                                                                                                            <>
                                                                                                                <img className="chart_frame-child3" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_5.svg`} />
                                                                                                                <div className="chart_ellipse-parent1">
                                                                                                                    <div className="chart_frame-child8"></div>
                                                                                                                    <div className="chart_div2">2</div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <img className="chart_frame-child3" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_1.svg`} />
                                                                                                                <div className="chart_ellipse-group">
                                                                                                                    <div className="chart_frame-child4"></div>
                                                                                                                    <div className="chart_separator">2</div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </> : <></>}
                                                                                            {index2 === 2 ? <>
                                                                                                <div className="chart_level-items1">
                                                                                                    <div className="chart_frame-parent16">
                                                                                                        {riskLevels[key][riskLevel] === 1 ?
                                                                                                            <>
                                                                                                                <img className="chart_frame-child5" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_5.svg`} />
                                                                                                                <div className="chart_ellipse-parent1">
                                                                                                                    <div className="chart_frame-child8"></div>
                                                                                                                    <div className="chart_div2">3</div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <img className="chart_frame-child5" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_1.svg`} />
                                                                                                                <div className="chart_ellipse-container">
                                                                                                                    <div className="chart_frame-child6"></div>
                                                                                                                    <div className="chart_div1">3</div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </> : <></>}
                                                                                            {index2 === 3 ? <>
                                                                                                <div className="chart_frame-parent17">
                                                                                                    {riskLevels[key][riskLevel] === 1 ?
                                                                                                        <>
                                                                                                            <img className="chart_frame-child7" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_5.svg`} />
                                                                                                            <div className="chart_ellipse-parent1">
                                                                                                                <div className="chart_frame-child8"></div>
                                                                                                                <div className="chart_div2">4</div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <img className="chart_frame-child7" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_1.svg`} />
                                                                                                            <div className="chart_ellipse-parent2">
                                                                                                                <div className="chart_frame-child10"></div>
                                                                                                                <div className="chart_div3" style={{ color: "#000" }}>4</div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </> : <></>}
                                                                                            {index2 === 4 ? <>
                                                                                                <div className="chart_frame-parent18">
                                                                                                    {riskLevels[key][riskLevel] === 1 ?
                                                                                                        <>
                                                                                                            <img className="chart_frame-child9" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_5.svg`} />
                                                                                                            <div className="chart_ellipse-parent1">
                                                                                                                <div className="chart_frame-child8"></div>
                                                                                                                <div className="chart_div2">5</div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <img className="chart_frame-child9" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/charts/chat_1.svg`} />
                                                                                                            <div className="chart_ellipse-parent2">
                                                                                                                <div className="chart_frame-child10"></div>
                                                                                                                <div className="chart_div3">5</div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </> : <></>}
                                                                                        </>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                            <div className="chart_frame-child11"></div>
                                                                            <div className="chart_intensity-level">
                                                                                <div className="chart_minimal">Minimal</div>
                                                                                <div className="chart_low">Low</div>
                                                                                <div className="chart_moderate">Moderate</div>
                                                                                <div className="chart_high">High</div>
                                                                                <div className="chart_intense">Intense</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </>
                }
            </div>


            <Modal show={showModal} onClose={() => setShowModal(false)} cswidth={cs_width}>
                <h4>What are the market conditions in {cityCounty?.county && <>{cityCounty?.county}</>} {cityCounty?.city && <>{cityCounty?.city}</>} {cityCounty?.group && <>{cityCounty?.group}</>} ? </h4>
                <div className="chart_container">
                    <div className="chart_card">
                        <h4>Number of Listings</h4>
                        <FirstChart chartList={chartObject} />
                    </div>
                    <div className="chart_card">
                        <h4>Days on Market</h4>
                        <SecondChart chartList={chartObject} />
                    </div>
                    <div className="chart_card">
                        <h4>Price/SqFt</h4>
                        <ThirdChart chartList={chartObject} />
                    </div>
                    <div className="chart_card">
                        <h4>Closing Price vs Listing Price</h4>
                        <FourthChart chartList={chartObject} />
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default MarketCharts;
