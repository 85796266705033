import React, { useEffect, useState, useRef } from 'react';
import { setSuggestedOffer } from '../../../slices/preferenceSlice';
import { setOfferButton } from '../../../slices/propertySlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { scrollToBottom } from '../../utils/Common';
import homeChatGif from '../../../assets/gifs/home_chat.gif';
import { getSuggestedOffer } from '../../services/apiService';
import ChatLoader from '../../services/chat-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


const ShowOffer = ({ index, pageName, dataFromParent }) => {

    const bottomRef = useRef(null);
    const dispatch = useDispatch();
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const propertyDetails = useSelector((state) => state.propertySlice.propertyDetails);
    const [stopAnimation, setStopAnimation] = useState(false);
    const [showSparkles, setShowSparkles] = useState(false);
    const [parentData, setparentData] = useState(false);
    const [checkApiCall, setCheckApiCall] = useState(false);
    const [offerDetails, setOfferDetails] = useState({});
    const lastTimestampRef = useRef(null);

    useEffect(() => {
        if (dataFromParent) {
            const currentTime = Date.now();
            const lastTimestamp = lastTimestampRef.current;
            const minTimeDiff = 3000; // Minimum time difference in milliseconds (3 seconds as an example)

            // Check if the last timestamp exists and if the time difference is too short
            if (lastTimestamp && (currentTime - lastTimestamp < minTimeDiff)) {
                // Time duration too short, skipping fetchOfferDetails
                return; // Skip the API call
            }

            // Update the last timestamp
            lastTimestampRef.current = currentTime;

            setparentData(dataFromParent?.isHistory);
            // Process the dataFromParent and send a response back to the parent
            const fetchOfferDetails = async () => {
                setCheckApiCall(true);
                try {
                    const response = await getSuggestedOffer(propertyId, pageName, { "is_history": dataFromParent?.isHistory });
                    if (response.code === 200) {
                        const suggestOffer = { "admin_input": response.response.admin_input, "suggestedOffer": response.response.suggested_offer, "estimatePrice": response?.response?.estimate_price, "price_difference": response.response.price_difference };
                        setOfferDetails(suggestOffer);
                        setCheckApiCall(false);
                        if (dataFromParent?.isHistory === false && suggestOffer?.admin_input !== 3) {
                            const timer = setTimeout(() => {
                                const content = document.querySelector(`.message-holder[data-index="${index}"] .main-content`);
                                if (content) {
                                    scrollToBottom(bottomRef);
                                    setparentData(true);
                                    dispatch(setSuggestedOffer(suggestOffer));
                                    dispatch(setOfferButton(true));
                                    
                                    setStopAnimation(true);
                                    setShowSparkles(true);
                                    const timer2 = setTimeout(() => {
                                        setStopAnimation(false);
                                        setShowSparkles(false);
                                    }, 4000);

                                    return () => clearTimeout(timer2);
                                }
                            }, 10000);
                            return () => clearTimeout(timer);
                        }
                        else {
                            dispatch(setSuggestedOffer(suggestOffer));
                            dispatch(setOfferButton(true));
                            scrollToBottom(bottomRef);
                        }

                    }
                }
                catch (error) { }
            };

            fetchOfferDetails();
        }
    }, [dataFromParent, dispatch, index, pageName, propertyId]);


    return (
        <>
            <div className={`message-holder mira-chat ml-85 mr-85 ${stopAnimation === false ? 'stop-animation' : ''}`} data-index={index}>
                <div className="message-bubble bg-green">
                    <div className="message-text">
                        {offerDetails?.admin_input !== 3 && <>
                            <div className={`${parentData === false ? 'loader-text text-center' : 'd-none'}`}>
                                <h6>Awesome Work!</h6>
                                <h6>Hold on while I update the offer price for you, based on what we have so far.</h6>
                                <img src={homeChatGif} width="80" alt="" />
                            </div>
                        </>}

                        {checkApiCall === true &&
                            <>
                                <div className="text-center">
                                    <ChatLoader />
                                </div>
                            </>
                        }

                        
                        {checkApiCall === false && offerDetails?.admin_input !== 3 &&
                            <>
                                <div className={`main-content ${parentData === false ? 'd-none' : ''} ${showSparkles === true ? 'flash-animation' : ''}`} >
                                    <div className={`${showSparkles === true ? 'sparkles' : 'd-none'}`}>
                                        <div className="sparkle bg-blue"></div>
                                        <div className="sparkle bg-orange"></div>
                                        <div className="sparkle bg-orange"></div>
                                        <div className="sparkle bg-blue"></div>
                                        <div className="sparkle bg-orange"></div>
                                    </div>

                                    {pageName === 'property_market' &&
                                        <>
                                            <b className="agent-mira font-size-mid">Awesome Work! </b><br />
                                            Here’s Agent Mira's estimate (offer price) for <b className="mrk_miami">{propertyDetails?.propertyUnparsedAddress}</b>, considering recent sales and market conditions: <span className="fw-bold"> {offerDetails?.suggestedOffer} </span> — that’s
                                            <span className="fw-bold"> {offerDetails?.price_difference} </span> than the list price.
                                        </>
                                    }


                                    {pageName === 'property_match' &&
                                        <>
                                            Here’s Agent Mira's estimate for <b className="mrk_miami">{propertyDetails?.propertyUnparsedAddress}</b>, considering the home quality, market conditions, and how well the home fits your needs: <span className="fw-bold"> {offerDetails?.suggestedOffer} </span> — that’s
                                            <span className="fw-bold"> {offerDetails?.price_difference} </span> than the list price.
                                        </>
                                    }
                                </div>
                            </>
                        }

                        {checkApiCall === false && offerDetails?.admin_input !== 1 &&
                            <>
                                {offerDetails?.admin_input === 3 && <>This property requires a manual relook and we will get back to you within 24 hours.</>}
                                {offerDetails?.admin_input === 2 && <>This is our initial estimate and we will confirm back within 24 hours in case there are any changes.</>}
                            </>
                        }
                    </div>
                </div>
            </div>
            {offerDetails?.admin_input !== 3 &&
                <>
                    <div>
                        {parentData === true && pageName === 'property_market' &&
                            <>
                                <div className={`message-holder mira-chat ml-85 mr-85`} data-index={index}>
                                    <div className="message-bubble">
                                        <div className="message-text">
                                            <div className={`main-content ${parentData === false ? 'd-none' : ''}`} >
                                                <div className="w-100 d-flex">
                                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2 font-size-xxl" />
                                                    {showSparkles === false ? (
                                                        <> This estimate is based on the home quality, comparable sales, and market conditions. We recommend going a step further by assessing how well this home meets your needs. But if you’d rather stop here, we can do that too!</>
                                                    ) : (
                                                        <ChatLoader />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </>
            }
        </>

    );
};

export default ShowOffer;
