// src/store/preferenceSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    questionId: null,
    cmaDataList: {},
    suggestedOffer: {},
    questions: [],
    isMarketSaved: false,
    isManorSaved: false,
};

const preferenceSlice = createSlice({
    name: 'preference',
    initialState,
    reducers: {
        addPreferenceQuestion: (state, action) => {
            state.questions = action.payload
        },
        clearPreferenceQuestionList: (state) => {
            state.questions = [];
        },
        setCmaList: (state, action) => {
            state.cmaDataList = action.payload;
        },
        setSuggestedOffer: (state, action) => {
            state.suggestedOffer = action.payload;
        },
        clearSuggestedOffer: (state) => {
            state.suggestedOffer = {};
        },
        clearCmaList: (state) => {
            state.cmaDataList = {};
        },
        setMarketSaved: (state) => {
            state.isMarketSaved = true;
        },
        setManorSaved: (state) => {
            state.isManorSaved = true;
        },
        clearMarket: (state) => {
            state.isMarketSaved = false;
        },
        clearManor: (state) => {
            state.isManorSaved = false;
        },
    },
});

export const { addPreferenceQuestion, clearPreferenceQuestionList, setCmaList, setSuggestedOffer, clearCmaList, clearSuggestedOffer, setMarketSaved, setManorSaved, clearMarket, clearManor } = preferenceSlice.actions;
export const preferenceData = (state) => state.preferenceSlice.questions;
export const getCma = (state) => state.preferenceSlice.cmaDataList;
export const getOfferSuggested = (state) => state.preferenceSlice.suggestedOffer;
export const getSavedMarketStatus = (state) => state.preferenceSlice.isMarketSaved;
export const getSavedManorStatus = (state) => state.preferenceSlice.isManorSaved;

export default preferenceSlice.reducer;
