import { React, useState, useEffect, useContext, useRef, useCallback } from "react";
import Header from "../../header/header";
import Sidebar from "../sidebar/sidebar";
import MatchChat from "./match-chat";
import { useSelector, useDispatch } from 'react-redux';
import { getQuestions, getMarkedList, getAllUserHistory, saveQnaQuestion } from '../../services/apiService';
import { addQuestion, matchData, clearMatchQuestionList } from '../../../slices/matchSlice';
import { DataBinder, shouldDispatchQuestion } from '../../utils/Common';
import { LoaderContext } from '../../services/LoaderContext';
import { getCma } from '../../../slices/preferenceSlice';
import NoComparable from "../../services/no-comparable";

const pageName = "property_match";

const MatchTab = () => {
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const dispatch = useDispatch();
    const questionList = useSelector(matchData);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const cmaDataList = useSelector(getCma);
    const hasFetchedData = useRef(false);

    const [propertyMatchQuestions, setPropertyMatchQuestions] = useState(questionList);

    const saveDefaultResponse = useCallback(async (questionId, pageName, propertyId, propertyMatchQuestions) => {
        try {
            // Save a default response
            const response = await saveQnaQuestion({ question_id: questionId, response: ["1"] }, pageName, propertyId);
            if (response.code === 200) {
                // Fetch updated questions after saving
                const apiResponse = await getQuestions(propertyId, pageName);
                if (apiResponse.code === 200) {
                    if (shouldDispatchQuestion(apiResponse.response, propertyMatchQuestions)) {
                        setPropertyMatchQuestions(prevQuestions => [
                            ...prevQuestions,
                            apiResponse.response // Add the new question response to state
                        ]);
                    }

                    if (apiResponse?.response?.response_options?.length === 0 && !apiResponse?.response?.hasOwnProperty('response')) {
                        setTimeout(async () => {
                            await saveDefaultResponse(apiResponse.response.question_id, pageName, propertyId, propertyMatchQuestions);
                        }, 1000);
                    }
                }
            }
        }
        catch (error) {}
    }, []);

    useEffect(() => {
        const processMatchMarkedData = async () => {
            await dispatch(clearMatchQuestionList());
            showLoader();
            const userMatchHistory = await getAllUserHistory(propertyId, pageName);
            const markedResponse = await getMarkedList(pageName, propertyId);
            const questionResponse = await getQuestions(propertyId, pageName);
            let finalQuestionList = [...DataBinder(userMatchHistory.response, markedResponse.response.property_match)]

            const questionExists = finalQuestionList.some(question => question.question_id === questionResponse.response.question_id);
            if (!questionExists) {
                finalQuestionList.push(questionResponse.response);
            }
            setPropertyMatchQuestions(finalQuestionList);

            if((questionResponse?.response?.response_options?.length === 0 || questionResponse?.response?.isFirst === true) && !questionResponse?.response.hasOwnProperty('response')){
                await saveDefaultResponse(questionResponse?.response.question_id, pageName, propertyId, propertyMatchQuestions);
            }
            hideLoader();
        };

        if (!hasFetchedData.current && propertyMatchQuestions.length <= 1 && cmaDataList && (Object.keys(cmaDataList)?.length > 0)) {
            processMatchMarkedData();
            hasFetchedData.current = true;
        }
    }, [propertyMatchQuestions, dispatch, propertyId, hideLoader, showLoader, cmaDataList, saveDefaultResponse]);
    
    useEffect(() => {
        if (propertyMatchQuestions.length > 0) {
            dispatch(clearMatchQuestionList());
            dispatch(addQuestion(propertyMatchQuestions));
        }
    }, [dispatch, propertyMatchQuestions]);


    return (
        <>
            <div className="d-flex page border-on flex-column">
                <Header />

                {cmaDataList && (Object.keys(cmaDataList)?.length > 0) ? (
                    <>
                        <Sidebar />

                        <div className="page-content">
                            <MatchChat propertyMatchQuestions={propertyMatchQuestions} setPropertyMatchQuestions={setPropertyMatchQuestions} pageName={pageName} />
                        </div>
                    </>
                ) :
                    <>
                        <div className="d-flex justify-content-center align-items-center align-from-top">
                            <div className="content">
                                <NoComparable isShow={true} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default MatchTab;
