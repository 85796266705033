import { React, useState, useEffect, useContext, useRef, useCallback } from "react";
import Header from "../../header/header";
import Sidebar from "../sidebar/sidebar";
import MarketChat from "./market-chat";
import { useSelector, useDispatch } from 'react-redux';
import { getQuestions, getMarkedList, saveQnaQuestion, getAllUserHistory } from '../../services/apiService';
import { addMarketQuestions, marketQuestions, clearMarketQuestionList } from '../../../slices/marketSlice';
import { shouldDispatchQuestion, DataBinder } from '../../utils/Common';
import { LoaderContext } from '../../services/LoaderContext';
import { getCma } from '../../../slices/preferenceSlice';
import NoComparable from "../../services/no-comparable";

const pageName = 'property_market';

const MarketTab = () => {
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const prevPropertyId = useRef(propertyId); 
    const dispatch = useDispatch();
    const questionList = useSelector(marketQuestions);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const cmaDataList = useSelector(getCma);
    const [propertyMarketQuestions, setPropertyMarketQuestions] = useState(questionList);
    const hasFetchedData = useRef(false);
    const fetchedOnce = useRef(false);

    const saveDefaultResponse = useCallback(async (questionId, pageName, propertyId, propertyMarketQuestions) => { 
        try {
            // Save a default response
            const response = await saveQnaQuestion({ question_id: questionId, response: ["1"] }, pageName, propertyId);
            if (response.code === 200) {
                // Fetch updated questions after saving
                const apiResponse = await getQuestions(propertyId, pageName);
                if (apiResponse.code === 200) {
                    if (shouldDispatchQuestion(apiResponse.response, propertyMarketQuestions)) {
                        setPropertyMarketQuestions(prevQuestions => [
                            ...prevQuestions,
                            apiResponse.response // Add the new question response to state
                        ]);
                    }

                    if (apiResponse?.response?.response_options?.length === 0 && !apiResponse?.response?.hasOwnProperty('response')) {
                        setTimeout(async () => {
                            await saveDefaultResponse(apiResponse.response.question_id, pageName, propertyId, propertyMarketQuestions);
                        }, 1000);
                    }
                }
            }
        }
        catch (error) {}
    }, []);

    const processMarketMarkedData = useCallback(async () => {
        await dispatch(clearMarketQuestionList()); // Clear existing data in Redux store
        showLoader();
    
        const userMarketHistory = await getAllUserHistory(propertyId, pageName);
        const markedResponse = await getMarkedList(pageName, propertyId);
        const questionResponse = await getQuestions(propertyId, pageName);
        let finalQuestionList = [...DataBinder(userMarketHistory.response, markedResponse.response.property_market)];
       
        const questionExists = finalQuestionList.some(question => question.question_id === questionResponse.response.question_id);
        if (!questionExists) {
            finalQuestionList.push(questionResponse.response);
        }
    
        setPropertyMarketQuestions(finalQuestionList);
        
        if ((questionResponse?.response?.response_options?.length === 0 || questionResponse?.response?.isFirst === true) && !questionResponse?.response.hasOwnProperty('response')) {
            await saveDefaultResponse(questionResponse?.response.question_id, pageName, propertyId, propertyMarketQuestions);
        }
        fetchedOnce.current = false;
        hideLoader();
    }, [dispatch, propertyId, hideLoader, showLoader, saveDefaultResponse, propertyMarketQuestions]);

    useEffect(() => {
        if (!hasFetchedData.current && propertyMarketQuestions.length <= 1 && cmaDataList && Object.keys(cmaDataList)?.length > 0) {
            processMarketMarkedData();
            hasFetchedData.current = true; // Ensure API call is only made once
        }

        if (prevPropertyId.current !== propertyId && !fetchedOnce.current) {
            processMarketMarkedData();
            // Update the previous propertyId to the current one
            prevPropertyId.current = propertyId;
            fetchedOnce.current = true;
        }
    }, [propertyId, propertyMarketQuestions, cmaDataList, processMarketMarkedData]); // Add propertyId and cmaDataList as dependencies


    useEffect(() => {
        if (propertyMarketQuestions.length > 0) {
            dispatch(clearMarketQuestionList());
            dispatch(addMarketQuestions(propertyMarketQuestions));
        }
    }, [dispatch, propertyMarketQuestions]);


    return (
        <>
            <div className="d-flex page border-on flex-column">
                <Header />

                {cmaDataList && (Object.keys(cmaDataList)?.length > 0) ? (
                    <>
                        <Sidebar />

                        <div className="page-content">
                            <MarketChat propertyMarketQuestions={propertyMarketQuestions} setPropertyMarketQuestions={setPropertyMarketQuestions} pageName={pageName} />
                        </div>
                    </>
                ) :
                    <>
                        <div className="d-flex justify-content-center align-items-center align-from-top">
                            <div className="content">
                                <NoComparable isShow={true} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default MarketTab;
