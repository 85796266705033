// Combined reducer store
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import loginSliceReducer from './slices/loginSlice';
import propertySliceReducer from './slices/propertySlice'
import preferenceSliceReducer from './slices/preferenceSlice'
import marketSliceReducer from './slices/marketSlice'
import manorSliceReducer from './slices/manorSlice'
import matchSliceReducer from './slices/matchSlice'
import userJourneySliceReducer from "./slices/userJourney";

const reducer = combineReducers({
    loginSlice: loginSliceReducer,
    propertySlice: propertySliceReducer,
    preferenceSlice: preferenceSliceReducer,
    marketSlice: marketSliceReducer,
    manorSlice: manorSliceReducer,
    matchSlice: matchSliceReducer,
    userJourneySlice: userJourneySliceReducer,
})

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoreActions: [
                FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
            ]
        }
    })
})

export const persistor = persistStore(store);
