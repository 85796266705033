import { useState, useEffect, useRef } from 'react';

import '../agent-mira/chat.css'; 
import { getChatResponse } from '../services/apiService';
import { scrollToBottom } from '../utils/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatLoader from '../services/chat-loader';
import DOMPurify from 'dompurify';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import agentMiraIcon from '../../assets/images/agent-icon.png';
import { loginData } from '../../slices/loginSlice';


const MiraChat = () => {
    const bottomRef = useRef(null);
    const [userInput, setInput] = useState('');
    const [chatList, setChatData] = useState([]);
    const [loading, setLoading] = useState(false);
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const [chatbotInputHasText, setChatbotInputHasText] = useState(false);
    const userDetails = useSelector(loginData);


    const handleInputChange = (event) => {
        setChatbotInputHasText(event.target.value.length > 0);
        setInput(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && userInput.trim() !== '') {
            setChatbotInputHasText(false)
            setChatMessages('user', userInput);
            getQuestionList();
        }
    };

    const getChat = () => {
        if (userInput.trim() !== '') {
            setChatMessages('user', userInput);
            getQuestionList();
        }
    };

    const getQuestionList = async () => {
        try {
            setLoading(true);
            scrollToBottom(bottomRef);
            const chat_response = await getChatResponse({"user_str": userInput, "page": "property_offer" },propertyId);
            if(chat_response.code === 200) {
                if(chat_response.response.mira_chat_response === null || chat_response.response.mira_chat_response === undefined){
                    setChatMessages('mira', "There is some issue with accessing our AI engine, can you please ask your question again.");
                }
                else {
                    setChatMessages('mira', chat_response.response.mira_chat_response);
                }
                scrollToBottom(bottomRef);
            } 
            else {
                setChatMessages('mira', "There is some issue with accessing our AI engine, can you please ask your question again.");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setChatMessages('mira', "There is some issue with accessing our AI engine, can you please ask your question again.");
            // toast.warning("Failed to fetch data.", error);
        }
    };

    const setChatMessages = (type, message) => {
        const newMessage = {
            "sender": type,
            "message": message
        };
        setChatData((prevChatList) => {
            const updatedChatList = [...prevChatList, newMessage];
            return updatedChatList;
        });
        setInput('');
    };

    useEffect(() => {

        if(chatList?.length > 0){
            scrollToBottom(bottomRef);
        }
        
    }, [chatList]);

    return (
        <>  
            {chatList?.length > 0 &&
                <>
                {chatList.map((chat, index) => (
                    <>
                        {chat.sender === 'mira' ? (
                            <div className="message-holder mira-chat ml-85 mr-85" key={index}>
                                <div className="message-bubble">
                                    <div className="message-text">
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chat.message) }} />
                                    </div>
                                </div>
                            </div>

                        ) : (
                            <div className="message-holder user-block ml-85" data-content={`${atob(userDetails?.firstName).charAt(0).toUpperCase()}${atob(userDetails?.lastName).charAt(0).toUpperCase()}`} key={index}>
                                <div className="message-bubble reply-bubble">
                                    
                                    <div className="message-text d-flex js-start">
                                        <div className="mb-2">
                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chat.message) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ))}
                </>
            }

            {loading ? (
                <div className="message-holder mira-chat ml-85 mr-85">
                    <div className="message-bubble">
                        <div className="message-text d-flex js-start">
                            <ChatLoader />
                        </div>
                    </div>
                </div>
                
            ) : (
                <></>                                    
            )}
           
            <div ref={bottomRef} />

           <footer className={`chat_footer ${loading ? 'chat_disable' : ''}`}>
                <div className="chat_wrapper8">
                    <div className="chat_ai_prompt_wrapper">
                        <div className="chat_ai_prompt">
                            {chatbotInputHasText && <div className="user-block custom-user-block" data-content={`${atob(userDetails?.firstName).charAt(0).toUpperCase()}${atob(userDetails?.lastName).charAt(0).toUpperCase()}`}></div>}
                            {!chatbotInputHasText && <img className="ms-1" src={agentMiraIcon} width="45" alt="" />}

                            <input className="chat_ask_anything" onKeyDown={handleKeyDown}  onChange={handleInputChange} value={userInput} autoFocus placeholder="Anything else you’d like to share?" type="text" />
                            <FontAwesomeIcon icon={faCircleChevronRight} onClick={getChat} className='font-size-mid color-blue chat_ai_prompt_child' />
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default MiraChat