import React, { useState, useEffect, useContext, useCallback } from "react";
import { toast } from 'react-toastify';
import Header from "../header/header";
import './login.css';
import { registerNewUser } from '../services/apiService';
import { LoaderContext } from '../services/LoaderContext';



const Register = () => {
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [payload, setPayload] = useState(false);


    const validateEmail = useCallback((email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }, []);

    const validateForm = useCallback(() => {
        const newErrors = {
            firstName: firstName === '',
            lastName: lastName === '',
            email: email === '' || !validateEmail(email),
            password: password === '',
        };
        setIsFormValid(!Object.values(newErrors).includes(true));
    }, [firstName, lastName, email, password, validateEmail]);

    useEffect(() => {
        validateForm();
    }, [firstName, lastName, email, password, validateForm]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid) {
            toast.error("Please fill in all fields correctly.");
            return;
        }

        try {
            showLoader();
            const payload ={
                "first_name":firstName,
                "last_name":lastName,
                "email_address":email,
                "state":"NW",
                "password":password
            }
            const response = await registerNewUser(payload);
            if (response.code === 200) {
                setPayload(payload);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPassword('');
                toast.success(firstName+ " user registered successfully!");
                // navigate('/login');
                setShowPopup(true);
                hideLoader();
            }
            else {
                toast.error(response.message);
                hideLoader();
            }
        } 
        catch (error) {
            hideLoader()
            toast.error("Registration failed. Please try again.");
        }
    };

    const closeSuccessModal = () => {
        setShowPopup(false);
        setPayload({});
    }

    return (
        <>
            <Header />
            <div className="d-flex signin-container login-bg">
                <div className="signin-container-pre">
                    <div className="chart_card custom-padding">
                        <div className="card-body">
                            <h3 className="card-title txt text-center mb-3">Sign Up</h3>
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <div className="mb-3">
                                    <label htmlFor="firstName" className="form-label form-text">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control custom-ip"
                                        id="firstName"
                                        placeholder="Enter your first name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="lastName" className="form-label form-text">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control custom-ip"
                                        id="lastName"
                                        placeholder="Enter your last name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label form-text">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control custom-ip"
                                        id="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label form-text">Password</label>
                                    <input
                                        type="password"
                                        className="form-control custom-ip"
                                        id="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary custom-btn w-100 mt-4" disabled={!isFormValid}>Register</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="image img-container">
                    <img src={`${process.env.PUBLIC_URL}/images/home/main/bg-main.svg`} alt="Placeholder" />
                </div>
            </div>

            {showPopup ?
                <div className="offer_done_popup">
                    <div className="popup_container flex-column justify-content-center align-center w-30">
                        <img alt="" src={`${process.env.PUBLIC_URL}/images/market/group-1171275857.svg`} style={{ "width": "50%" }} />
                        <div className="heres-your-offer">
                            Congratulations!
                        </div>
                        <p className="mt-3"><span className="fw-bold">{payload?.email_address}</span></p>
                        <p className="mt-2">
                            <span className="fw-bold">{payload?.first_name} {payload?.last_name}</span> has been registered successfully!
                        </p>
                        <div className="d-flex w-100 justify-content-evenly">
                            <button className="ssx_learn-more-wrapper justify-content-center w-min" onClick={() => closeSuccessModal()}><div className="ssx_learn-more1 text-center">Back to home</div></button>
                        </div>
                    </div>
                </div>
            : <></>}
        </>
    );
};

export default Register;
