import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import '../../../assets/css/sidebar.css';
import { useNavigate } from "react-router-dom";
import { getAccountHistory} from '../../services/apiService';
import { useSelector, useDispatch } from 'react-redux';
import { LoaderContext } from '../../services/LoaderContext';
import { toast } from "react-toastify";

import { setPropertyAddress, setPropertyId, setPropertyDetails, setPropertyImages, clearPropertyDetails } from '../../../slices/propertySlice';
import { clearSuggestedOffer, clearMarket, clearManor, setCmaList } from '../../../slices/preferenceSlice';
import { clearMarketQuestionList } from '../../../slices/marketSlice';
import { clearManorQuestionList } from '../../../slices/manorSlice';
import { clearMatchQuestionList } from '../../../slices/matchSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const hasFetched = useRef(false);
    const prevPropertyId = useRef(propertyId); 
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isClickable, setIsClickable] = useState(false);
    const [propertyList, setPropertyList] = useState([]);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const dispatch = useDispatch();


    const getHistoryList = useCallback(async () => {
        setPropertyList([]);
        const response = await getAccountHistory(propertyId);
        if (response?.code === 200) {
            if(response?.response?.length > 0){
                setPropertyList(response.response);
            }
        } 
    }, [propertyId]);

    useEffect(() => {
        if(!hasFetched.current && propertyList?.length === 0){
            getHistoryList();
            hasFetched.current = true;
        }
    }, [getHistoryList, propertyList])

    // Load when propertyId changed
    useEffect(() => {
        if (prevPropertyId.current !== propertyId) {
            getHistoryList();
            // Update the previous propertyId to the current one
            prevPropertyId.current = propertyId;
        }
    }, [getHistoryList, propertyId]); 

    const openCloseSidebar = () => {
        setIsExpanded((prevState) => !prevState);
    };
    
    const goToProperty = async (listing) => {
        setIsClickable(true);
        openCloseSidebar();
        showLoader();
        
        if(listing && Object.keys(listing).length > 0){
            
            // Redux store info cleared
            dispatch(clearPropertyDetails());
            dispatch(clearMarketQuestionList());
            // dispatch(clearPreferenceQuestionList());
            dispatch(clearMatchQuestionList());
            dispatch(clearManorQuestionList());
            dispatch(clearSuggestedOffer());
            dispatch(clearManor());
            dispatch(clearMarket());

            dispatch(setPropertyAddress(listing.property_address));
            dispatch(setPropertyId(listing.property_id));
            dispatch(setCmaList(listing.cma_list));
            if(Object.keys(listing?.images).length > 0){
                dispatch(setPropertyImages(listing?.images[0]));
            }
            
            if(Object.keys(listing?.cma_list?.subjectProperty).length > 0){ 
                dispatch(setPropertyDetails({
                    propertyBeds: listing?.cma_list?.subjectProperty?.BedroomsTotal,
                    propertyBaths: listing?.cma_list?.subjectProperty?.BathroomsTotalDecimal,
                    propertySqft: listing?.cma_list?.subjectProperty?.LivingArea,
                    propertyListPrice: listing?.cma_list?.subjectProperty?.ListPrice,
                    propertyUnparsedAddress: listing?.cma_list?.subjectProperty?.UnparsedAddress
                }));
            }
        
            navigate(`/${listing.property_id}/market`, { replace: true });
            setIsClickable(false);
            hideLoader();
        }
        else {
            toast.error("Unable to load the data.")
        }
    };
    

    return (
        <>
            {isExpanded && <div className="sidebar-overlay" onClick={openCloseSidebar}></div>}

            {propertyList && propertyList?.length > 0 && 
                <>
                <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
                    {isExpanded && (
                        <div className="close-icon" onClick={openCloseSidebar}>
                        <FontAwesomeIcon icon={faTimes} />
                        </div>
                    )}
                    <div className="sidebar_content">
                        <div className="pre_chat_rectangle_group">
                            <div className="pre_chat_menu_content">
                                <img onClick={openCloseSidebar} className="pre_chat_menu_open_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/menu-open.svg`} />
                                <div className={isExpanded ? 'pre_chat_account_history_parent' : 'hide_sidebar'}>
                                    <div className="pre_chat_account_history">Account history</div>
                                    <img className="pre_chat_group_icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/market/group-1171275857.svg`} />
                                </div>
                            </div>
                            <div className={`sidebar-height ${isExpanded ? 'pre_chat_frame_parent1' : 'hide_sidebar'}`}>
                                {propertyList.map((item) => (
                                    <div className={`pre_active pre_chat_frame_parent2 ${isClickable ? 'disable' : ''}`} key={item?.property_id} onClick={() => goToProperty(item)}>
                                        <div className="pre_chat_frame_wrapper2">
                                            {item?.images?.length > 0 && <><img className="pre_chat_frame_icon" loading="lazy" alt="" src={`data:image/jpeg;base64,${item['images'][0]}`} /></>}
                                            {item?.images?.length === 0 && <> <div className=" bg-gray" /></>}
                                        </div>
                                        <div className="pre_chat_frame_parent3">
                                            <div className="pre_chat_sw_22nd_ter_miami_fl_33129_group">
                                                <b className="pre_chat_sw_22nd_ter_miami_container1">
                                                    <p className="pre_chat_sw_22nd_ter_miami1">{item?.property_address}</p>
                                                </b>
                                                <div className="pre_chat_suggested_offer_parent">
                                                    <div className="pre_chat_suggested_offer1">Suggested Offer</div>
                                                    <div className="pre_chat_icon_container">
                                                        <b className="pre_chat_empty_container">{item?.offer_price}</b>
                                                        {/* <img className="pre_chat_phinfo_fill_icon1" alt="" src={`${process.env.PUBLIC_URL}/images/market/phinfofill.svg`} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pre_chat_for_sale_wrapper">
                                                <div className="pre_chat_for_sale1">For Sale</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                </>
            }
        </>
    );
};

export default Sidebar;
