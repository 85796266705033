import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import Header from "../header/header";
import { LoaderContext } from '../services/LoaderContext';
import Modal from '../utils/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { allUserList, updateUserAccess } from '../services/apiService';
import { toast } from 'react-toastify';

const cs_width = "35%";

const UserManagement = () => {
    const rowsPerPage = 10;
    const bottomRef = useRef(null);
    const hasFetchedData = useRef(false);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);


    const getUsersList = useCallback(async () => {
        showLoader();
        try {
            const response = await allUserList();
            if (response.code === 200) {
                setSelectedData(response.response);
            }
            hideLoader();
        } 
        catch (error) {
            hideLoader();
        } 
    }, [showLoader, hideLoader]);

    useEffect(() => {
        if (!hasFetchedData.current) {
            getUsersList();
            hasFetchedData.current = true;
        }
    }, [hideLoader, showLoader, getUsersList]);

    // Filter data based on search term, only if selectedData is available
    const filteredData = selectedData && selectedData.length > 0 ? selectedData.filter((row) => {
        return (
            row?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row?.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row?.email?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }): []; // If no data, return an empty array

    // Calculate total pages after filtering
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    // Paginate filtered data
    const paginateData = (data, page, rowsPerPage) => {
        const start = (page - 1) * rowsPerPage;
        return data.slice(start, start + rowsPerPage);
    };

    const currentData = paginateData(filteredData, currentPage, rowsPerPage);

    // Handle pagination and search input
    const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on new search
    };

    const handleSelectedRow = (row) => {
        // Set selected data when checkbox is clicked
        checkModal();
        setSelectedUser(row);
    };

    const checkModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const updateAccess = async () => {
        showLoader();
        try {
            let isAccess = 0;
            if(selectedUser?.full_access === 0){isAccess = 1}
            
            const response = await updateUserAccess({"full_access": isAccess, "email": selectedUser?.email, "lite_access": 1});
            if (response.code === 200) {
                checkModal();
                updateAccessData(selectedUser, isAccess)
                toast.success("Access updated successfully!");
            }
            hideLoader();
        }
        catch (error) {
            hideLoader();
        }
    };

    const updateAccessData = (selectedUser, isAccess) => {
        const updateList = selectedData.map(item =>
            item.email === selectedUser.email
                ? { ...item, full_access: isAccess, subscription_request: false }
                : item
        );
        setSelectedData(updateList); 
    };


    return (
        <>
            <div className="d-flex page border-on flex-column">
                <Header />

                <div className="d-flex justify-content-center" ref={bottomRef}>
                    <div className="scrollable-content mb-5 w-100 flex-justify-center flex-content-center justify-content-center justify-items-center">
                        <div className="container d-flex justify-content-center mt-3">
                            <div className="content mt-5">
                                {/* Search Input */}
                                <div className="d-flex js-end">
                                    <input type="text" className="form-control mb-3 w-30" placeholder="Search by user name or email..."  value={searchTerm} onChange={handleSearchChange} />
                                </div>
                                
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th className="w-10">ID</th>
                                            <th className="w-30">Full Name</th>
                                            <th className="w-30">Email</th>
                                            <th className="w-10">Access Level</th>
                                            <th className="w-10">Edit Access</th>
                                            <th className="w-10">User Request</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData.map((row, index) => (
                                            <tr key={row.id}>
                                                <td className="w-10">{(index+1)}</td>
                                                <td className="w-30">{row.first_name} {row.last_name}</td>
                                                <td className="w-30">{row.email}</td>
                                                <td className="w-10">
                                                    {row?.lite_access === 1 && row?.full_access === 0 && <>Lite access</>}
                                                    {row?.full_access === 1 && <>Full access</>}
                                                </td>
                                                <td className="text-center w-10">
                                                    {/* Centered Checkbox */}
                                                    <FontAwesomeIcon className="cursor-pointer color_blue" icon={faEdit} onClick={() => handleSelectedRow(row)} />
                                                </td>
                                                <td className="text-center w-10">
                                                    {row?.subscription_request && <>
                                                        <div class="blinking-dot"></div>
                                                    </>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {/* Pagination Controls */}
                                <nav>
                                    <ul className="pagination justify-content-center">
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={goToPreviousPage}>Previous</button>
                                        </li>
                                        <li className="page-item">
                                            <span className="page-link">Page {currentPage} of {totalPages}</span>
                                        </li>
                                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={goToNextPage}>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onClose={checkModal} cswidth={cs_width}>
                <div className="mb-2">
                    {selectedUser?.full_access === 0 && <>
                        <label for="userEmail" className="form-label">Are you sure you want to provide full platform access to: <span className="fw-bold">{selectedUser?.first_name} {selectedUser?.last_name}</span> </label>
                    </>}

                    {selectedUser?.full_access === 1 && <>
                        <label for="userEmail" className="form-label">Are you sure you want to revoke full platform access for:  <span className="fw-bold">{selectedUser?.first_name} {selectedUser?.last_name}</span></label>
                    </>}

                    <div className="mb-3">Email: <span className="fw-bold ">{selectedUser?.email}</span></div>
                    
                    <div className="d-flex js-center">
                        <div className="button-primary mw-10em" onClick={updateAccess}>
                            Update Access
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UserManagement;
