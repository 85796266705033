import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    questions: [],
    rangeInfo: {},
};

const marketSlice = createSlice({
    name: 'market',
    initialState,
    reducers: {
        addMarketQuestions: (state, action) => {
            state.questions = action.payload
        },
        setRange: (state, action) => {
            state.rangeInfo = action.payload;
        },
        clearRangeList: (state) => {
            state.rangeInfo = {};
        },
        clearMarketQuestionList: (state) => {
            state.questions = [];
        }
    },
});

export const { addMarketQuestions, setRange, clearRangeList, clearMarketQuestionList } = marketSlice.actions;
export const marketQuestions = (state) => state.marketSlice.questions;
export const rangeInfo = (state) => state.marketSlice.rangeInfo;

export default marketSlice.reducer;
