import React, { useEffect, useState, useContext, useCallback } from "react";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { login } from '../../slices/loginSlice.js';
import './login.css';
import imageSrc from '../../assets/images/google.png';
import { sessionExists } from '../utils/Common';
import { userLogin, getPropertyDetails, propertyMarketRange, registerNewUser, userSSOLogin } from "../services/apiService.js";
import { useAuth } from '../utils/AuthContext.js';
import { LoaderContext } from '../services/LoaderContext';

import { setPropertyAddress, setPropertyId, setPropertyDetails, setPropertyImages, clearPropertyDetails } from '../../slices/propertySlice';
import { clearSuggestedOffer, clearMarket, clearManor, setCmaList, clearPreferenceQuestionList } from '../../slices/preferenceSlice';
import { clearMarketQuestionList, setRange, clearRangeList, } from '../../slices/marketSlice';
import { clearManorQuestionList } from '../../slices/manorSlice';
import { clearMatchQuestionList } from '../../slices/matchSlice';
import { useGoogleLogin } from '@react-oauth/google';

const Login = () => {
    const { login: authLogin } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [formType, setFormType] = useState(false);

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contact, setContact] = useState('');
    const [homeJourney, setHomeJourney] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [payload, setPayload] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (sessionExists()) {
            navigate(`/`);
        }
    }, [navigate]);

    const validateEmail = useCallback((email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }, []);

    const getRange = useCallback(async (list) => {
        dispatch(clearRangeList());
        try {
            const response = await propertyMarketRange({ "listing_id": list.propertyId });
            if (response.code === 200) {
                dispatch(setRange(response.response));
            }
        } catch (error) {
            // handle error if necessary
        }
    }, [dispatch]);

    const getPropertyInformation = useCallback(async (list) => {
        const response = await getPropertyDetails(list.propertyId);
        if (response.code === 200) {
            const propertyInfo = response.response;
            // Clear previous property-related info in Redux store
            dispatch(clearPropertyDetails());
            dispatch(clearMarketQuestionList());
            dispatch(clearPreferenceQuestionList());
            dispatch(clearMatchQuestionList());
            dispatch(clearManorQuestionList());
            dispatch(clearSuggestedOffer());
            dispatch(clearManor());
            dispatch(clearMarket());

            // Set new property information
            dispatch(setPropertyAddress(propertyInfo.property_address));
            dispatch(setPropertyId(list.propertyId));
            dispatch(setCmaList(propertyInfo.cma_list));
            if (propertyInfo?.images && propertyInfo.images.length > 0) {
                dispatch(setPropertyImages(propertyInfo.images[0]));
            }

            if (propertyInfo?.cma_list?.subjectProperty) {
                dispatch(
                    setPropertyDetails({
                        propertyBeds: propertyInfo.cma_list.subjectProperty.BedroomsTotal,
                        propertyBaths: propertyInfo.cma_list.subjectProperty.BathroomsTotalDecimal,
                        propertySqft: propertyInfo.cma_list.subjectProperty.LivingArea,
                        propertyListPrice: propertyInfo.cma_list.subjectProperty.ListPrice,
                        propertyUnparsedAddress: propertyInfo.cma_list.subjectProperty.UnparsedAddress,
                    })
                );
            }

            toast.success("Login successful!");
            navigate(`/${atob(list.isAccessed)}`, { replace: true });
        }
        hideLoader();
    }, [dispatch, hideLoader, navigate]);

    const routeToHomePage = useCallback((response) => {
        dispatch(login(response.response));
        authLogin(response.response.token);
        sessionStorage.setItem('token', response.response.token);
        if (response.response.isAccessed === '') {
            toast.success("Login successful!");
            navigate(`/`);
            hideLoader();
        } else {
            getRange(response.response);
            getPropertyInformation(response.response);
        }
    },[authLogin, dispatch, hideLoader, navigate, getPropertyInformation, getRange]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(loginEmail)) {
            toast.error("Please enter a valid email address");
            return false;
        }
        if (loginPassword === "") {
            toast.error("Please enter your password");
            return false;
        }

        try {
            showLoader();
            const payload = { "username": loginEmail, "password": loginPassword };
            const response = await userLogin(payload);
            if (response.code === 200) {
                routeToHomePage(response);
            }
            else {
                toast.error(response.data.message);
                hideLoader();
            }
        }
        catch (error) {
            hideLoader();
            toast.error("Login failed. Please try again.");
        }
    };

    const handleGoogleLoginSuccess = async (tokenResponse) => {
        try {
            const { access_token } = tokenResponse;
            // Send the token to the backend for verification and user handling
            const response = await userSSOLogin({ "google_token": access_token });
            if (response.code === 200) {
                routeToHomePage(response);
            }
            else {
                toast.error("Authentication failed. Please try again.");
            }
        }
        catch (error) {
            toast.error("Authentication failed. Please try again.");
        }
    };

    // Configure Google Login
    const loginWithGoogle = useGoogleLogin({
        onSuccess: handleGoogleLoginSuccess,
        onError: () => toast.error("Google Login Failed"),
    });

    
    // =====================================Signup================================
    // ===========================================================================
    const validateForm = useCallback(() => {
        const newErrors = {
            firstName: firstName.trim() === '' ? "First name is required." : '',
            lastName: lastName.trim() === '' ? "Last name is required." : '',
            email: email.trim() === '' ? "Email is required." : !validateEmail(email) ? "Enter a valid email address." : '',
            password: password.trim() === '' ? "Password is required." : password.length < 5 ? "Password must be at least 5 characters." : '',
            contact: contact.trim() === '' ? "Contact number is required." : !/^\d{10}$/.test(contact) ? "Contact number must be exactly 10 digits." : '',
            homeJourney: homeJourney === '' || homeJourney === 'Select an option' ? "Please select a valid stage in your home journey." : '',
        };
    
        const firstError = Object.values(newErrors).find((error) => error !== '');
        if (firstError && isSubmitted) {
            toast.error(firstError); // Show the first error message
        }
        
        // Return the validation status (true if valid, false if invalid)
        return Object.values(newErrors).every((error) => error === '');
    }, [firstName, lastName, email, password, contact, homeJourney, isSubmitted, validateEmail]);
    
    const handleSignupSubmit = async (e) => {
        e.preventDefault();
    
        // Mark form as submitted
        setIsSubmitted(true);
    
        // Validate the form
        const isValid = validateForm();
        if (!isValid) {
            // Prevent submission if form is invalid
            return;
        }
    
        try {
            showLoader();
            const payload = {
                first_name: firstName,
                last_name: lastName,
                email_address: email,
                state: "NW",
                password: password,
                home_journey: homeJourney,
                phone_number: contact
            };

            const response = await registerNewUser(payload);
            if (response.code === 200) {
                setPayload(payload);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPassword('');
                setContact('');
                setHomeJourney('');
                setIsSubmitted(false); // Reset submission state
                toast.success(`${firstName} user registered successfully!`);
                setShowPopup(true);

                setTimeout(() => {
                    closeSuccessModal();
                }, 2000);
            } 
            else {
                toast.error(response.message);
            }
            hideLoader();
        } catch (error) {
            hideLoader();
            toast.error("Registration failed. Please try again.");
        } finally {
            hideLoader();
        }
    };

    const closeSuccessModal = () => {
        setShowPopup(false);
        setPayload({});
        setFormType(false)
    }

    return (
        <>
            <div className="login-container">
                <div className="image-section"></div>
                <div className="card-section">
                    <div className={`chart_card custom-padding ${formType ? 'signup-h ' : ''}`}>
                        <div className="card-body">
                            <div className="d-flex gap-30 align-items-center mb-3">
                                <div className="logo">
                                    <img loading="lazy" width="50" alt="" src={`${process.env.PUBLIC_URL}/images/logo/logo.png`} />
                                </div>
                                <div className="sub-text">
                                    <h3 className="title-text text-center">Offer Savant</h3>
                                    <p className="card-title txt text-center mb-3">Let's craft a winning offer together</p>
                                </div>
                            </div>

                            {formType &&
                                <>
                                    <form onSubmit={handleSignupSubmit} autoComplete="off">
                                        <div className="mb-3">
                                            <label htmlFor="firstName" className="form-label form-text">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control custom-ip"
                                                id="firstName"
                                                placeholder="Enter your first name"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="lastName" className="form-label form-text">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control custom-ip"
                                                id="lastName"
                                                placeholder="Enter your last name"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="phoneNo" className="form-label form-text">Phone No</label>
                                            <input
                                                type="number"
                                                className="form-control custom-ip"
                                                id="phoneNo"
                                                placeholder="Enter your phone number"
                                                value={contact}
                                                onChange={(e) => setContact(e.target.value)}
                                            />
                                        </div>
                                        <div class="mb-3">
                                            <label for="home_jurney" class="form-label">What stage are you at in your home journey?</label>
                                            <select class="form-select" id="home_jurney" value={homeJourney} onChange={(e) => setHomeJourney(e.target.value)}>
                                                <option >Select an option</option>
                                                <option value="Just getting started">Just getting started</option>
                                                <option value="Casually looking">Casually looking</option>
                                                <option value="Actively looking">Actively looking</option>
                                                <option value="Ready to close">Ready to close</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label form-text">Email address</label>
                                            <input
                                                type="email"
                                                className="form-control custom-ip"
                                                id="email"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label form-text">Password</label>
                                            <input
                                                type="password"
                                                className="form-control custom-ip"
                                                id="password"
                                                placeholder="Enter your password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>

                                        <button type="submit" className="btn btn-primary custom-btn w-100 mt-4">Register</button>
                                    </form>

                                    <button type="button" className="btn btn-primary custom-btn-g w-100 mt-3" onClick={loginWithGoogle}>
                                        <img src={imageSrc} alt="Sign In" className="text-start sign-in" /> Sign up with Google
                                    </button>

                                    <button type="button" className="btn btn-primary custom-btn-g w-100 mt-3" onClick={() => setFormType(false)}>
                                        Back to Login
                                    </button>
                                </>
                            }

                            {!formType &&
                                <>
                                    <form onSubmit={handleSubmit} autoComplete="off">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label form-text">Email address</label>
                                            <input
                                                type="email"
                                                className="form-control custom-br"
                                                id="email"
                                                placeholder="Enter your email"
                                                value={loginEmail}
                                                onChange={(e) => setLoginEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label form-text">Password</label>
                                            <input type="password" className="form-control custom-br" id="password" placeholder="Enter your password" value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)}
                                            />
                                        </div>

                                        <button type="submit" className="btn btn-primary custom-btn w-100 mt-4">Submit</button>
                                    </form>

                                    <button type="button" className="btn btn-primary custom-btn-g w-100 mt-3" onClick={loginWithGoogle}>
                                        <img src={imageSrc} alt="Sign In" className="text-start sign-in" /> Sign in with Google
                                    </button>

                                    <button type="button" className="btn btn-primary custom-btn-g w-100 mt-3" onClick={() => setFormType(true)}>
                                        <span>Don't have an account? </span> Sign up
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {showPopup ?
                <div className="offer_done_popup">
                    <div className="popup_container flex-column justify-content-center align-center w-40">
                        <img alt="" src={`${process.env.PUBLIC_URL}/images/market/group-1171275857.svg`} style={{ "width": "50%" }} />
                        <div className="heres-your-offer">
                            Congratulations!
                        </div>
                        <p className="mt-3"><span className="fw-bold">{payload?.email_address}</span></p>
                        <p className="mt-2">
                            <span className="fw-bold">{payload?.first_name} {payload?.last_name}</span> has been registered successfully!
                        </p>
                        <div className="d-flex w-100 justify-content-evenly">
                            <button className="ssx_learn-more-wrapper justify-content-center w-min" onClick={() => closeSuccessModal()}><div className="ssx_learn-more1 text-center">Back</div></button>
                        </div>
                    </div>
                </div>
                : <></>}
        </>
    );
};

export default Login;
