import React from 'react';
import '../../assets/css/modal.css';

const Modal = ({ show, onClose, cswidth, children }) => {
    // Ensure onClose is a valid function
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    if (!show) {
        return null;
    }

    const inputStyle = {
        width: cswidth
    };

    return (
        <div className="modal_overlay" onClick={handleClose}>
            <div className="modal_content" style={inputStyle} onClick={e => e.stopPropagation()}>
                <button className="modal_close" onClick={handleClose}>×</button>
                <div className="modal_body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
