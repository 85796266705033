import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [token, setToken] = useState(() => {
        const storedToken = sessionStorage.getItem('token');
        return storedToken ? storedToken : null;
    });

    const login = (newToken) => {
        setToken(newToken);
        sessionStorage.setItem('token', newToken);
    };

    const logout = () => {
        setToken(null);
        sessionStorage.clear();
        localStorage.clear();
        navigate('/login'); // Navigate to login page
    };

    useEffect(() => {
        // If there is no token and user is not logged in, redirect to login
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    return (
        <AuthContext.Provider value={{ token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
