import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Header from '../header/header'
import HomeContent from './content'

import { setPage } from '../../slices/userJourney'

import './home.css'
import HomeChat from './chat'

const Home = () => {
    const dispatch = useDispatch();
    const { propertyid } = useParams();

    // validate the propertyid so that we can route the user details and if not, return error
    const validatePropertyId = (id) => {
        if (!id || id.trim() === '') {
            return false
        }
        else {
            if (id.trim().length > 3) {
                return true;
            }
            else {
                return false
            }
        }
    }

    useEffect(() => {
        if (propertyid !== "" && validatePropertyId(propertyid)) {
            dispatch(setPage(`/${propertyid}`))
        }
        else {
            dispatch(setPage('/'))
        }
    }, [dispatch, propertyid])

    return (
        <div className="d-flex page flex-column">
            <Header />
            <div className="d-flex justify-content-center align-items-center">
                <div className="scrollable-content w-100 flex-justify-center flex-content-center justify-content-center align-items-center">
                    {validatePropertyId(propertyid) ?
                        <HomeChat propertyid={propertyid} />
                        :
                        <HomeContent />
                    }
                </div>
            </div>
        </div>
    )
}

export default Home