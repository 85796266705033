import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    questions: []
};

const manorSlice = createSlice({
    name: 'manor',
    initialState,
    reducers: {
        addQuestion: (state, action) => {
            state.questions = action.payload;
        },
        clearManorQuestionList: (state) => {
            state.questions = [];
        }
    },
});

export const { addQuestion, clearManorQuestionList } = manorSlice.actions;
export const manorData = (state) => state.manorSlice.questions;

export default manorSlice.reducer;
