import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import agentMiraIcon from '../../assets/images/agent-icon.png';
import { loginData } from '../../slices/loginSlice';
import { useNavigate } from "react-router-dom";


const suggestion_one = process.env.REACT_APP_SUGGESTION_ONE;
const suggestion_two = process.env.REACT_APP_SUGGESTION_TWO;

const ChatInput = ({ userInput, handleInputChange, handleKeyDown, getChat, getChatSuggestions, loading, pageName, questions }) => {

    const userDetails = useSelector(loginData);
    const navigate = useNavigate();
    const [chatbotInputHasText, setChatbotInputHasText] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const handleInput = (e) => {
        handleInputChange(e);
        setChatbotInputHasText(e.target.value.length > 0);
    };

    const handleInputKey = (e) => {
        handleKeyDown(e);
        if (e.key === 'Enter') {
            setChatbotInputHasText(false);
        }
    };

    useEffect(() => {
        if (pageName === "property_market") {
            const isShow = questions.some(item => item.isLast === true);
            setIsDisable(isShow);
        }
    }, [questions, pageName]);

    return (
        <>
            <div className='d-flex flex-row align-items-start justify-content-between button-wrap'>
                <div className='d-flex flex-column flex1 flex-wrap chat-container'>
                    <div className={`chat-input-area ${loading ? 'chat_disable' : ''}`}>
                        <div className="chat-main">
                            <div className="d-flex js-start">
                                {chatbotInputHasText ? (
                                    <div
                                        className="user-block custom-user-block"
                                        data-content={`${atob(userDetails?.firstName).charAt(0).toUpperCase()}${atob(userDetails?.lastName).charAt(0).toUpperCase()}`}
                                    ></div>
                                ) : (
                                    <img src={agentMiraIcon} width="40" alt="" />
                                )}
                                <input
                                    type="text"
                                    placeholder="Any questions or comments?..."
                                    className="chat-input"
                                    onKeyDown={handleInputKey}
                                    onChange={handleInput}
                                    value={userInput}
                                />
                                <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                    onClick={getChat}
                                    className="font-size-mid color-blue chat_ai_prompt_child"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`d-flex js-space-between flex-row align-items-stretch align-items-center chat-input-area pt-0 gap-20 ${loading ? 'chat_disable' : ''}`}
                    >
                        <div className="suggest-card flex-1" onClick={() => getChatSuggestions(suggestion_one)}>
                            <div className="suggest-description d-flex js-space-between">{suggestion_one}</div>
                        </div>
                        <div className="suggest-card flex-1" onClick={() => getChatSuggestions(suggestion_two)}>
                            <div className="suggest-description d-flex js-space-between">{suggestion_two}</div>
                        </div>
                    </div>
                </div>

                <div className="nav-buttons">
                    {pageName === 'property_market' && isDisable === true && userDetails?.full_access === 1 && (
                        <button className={`button-primary mw-10em animated-button ${userDetails?.full_access === 0 ? 'disable' : ''}`} onClick={() => navigate(`/${propertyId}/match`)} >
                            Keep Going
                        </button>
                    )}
                    
                    {pageName === 'property_market' && isDisable === true && userDetails?.full_access === 0 && (
                        <button className="button-primary mw-10em animated-button" onClick={() => navigate(`/update-access`)} >
                            Keep Going
                        </button>
                    )}

                    {pageName === 'property_match' && userDetails?.full_access === 1 && (
                        <button className={`button-secondary mw-10em animated-button ${userDetails?.full_access === 0 ? 'disable' : ''}`} onClick={() => navigate(`/${propertyId}/offer`)} >
                            Generate Offer!
                        </button>
                    )}
                </div>
            </div>

        </>
    );
};

export default ChatInput;
