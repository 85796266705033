import Header from '../header/header';
import '../agent-mira/chat.css';  
import MiraChat from './mira-chat';

const AgentMira = () => {
    

    return (
        <>
            <div className="d-flex page border-on flex-column">
                <Header />
                <div className="page-content d-flex justify-content-center">
                    <div className="scrollable-content w-100 flex-justify-center flex-content-center justify-content-center justify-items-center">
                        <section className="welcome-message">
                            <MiraChat />
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgentMira